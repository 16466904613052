import _toConsumableArray from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import React from "react";
var __jsx = React.createElement;
import { useContext, useState } from 'react';
import { Context } from './MapStateProvider';
import Select from '../../atoms/Select';
import Heading from '../../atoms/Heading';
import FilterBar from '../../../static/images/icons/FilterBar';
import { BUSINESS_TYPE, BUSINESS_TYPE_FOLDER, businessTypeMap, filterLocationsMap } from './constants';
function Filters(_ref) {
  var businessLineLocations = _ref.businessLineLocations;
  var _useContext = useContext(Context),
    handleBusinessTypeFilter = _useContext.handleBusinessTypeFilter;
  var _useState = useState(BUSINESS_TYPE.ALL),
    selectedBusinessType = _useState[0],
    setSelectedBusinessType = _useState[1];

  // Build businessTypes object based on CMS selected values
  var businessTypes = businessLineLocations.map(function (businessLine) {
    var type = filterLocationsMap[businessLine];
    return {
      label: businessTypeMap[type],
      value: type
    };
  });
  businessTypes.unshift({
    label: businessTypeMap[BUSINESS_TYPE.ALL],
    value: BUSINESS_TYPE.ALL
  });
  return __jsx("div", {
    className: "nva-maps-hhpets__filters filters"
  }, __jsx(Heading, {
    HeadingType: "h3",
    className: "nva-maps-hhpets__filters-heading caption h3-static-size"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(FilterBar, {
    className: "filter-bar"
  })), "Filters"), __jsx("div", {
    className: "nva-maps-hhpets__filter-select-wrapper filter-select-container"
  }, businessLineLocations.length > 1 && __jsx(Select, {
    className: "nva-maps-hhpets__business-type-filter businesstype-filter",
    options: businessTypes,
    selectedOption: selectedBusinessType,
    onChange: function onChange(e) {
      setSelectedBusinessType(e.target.value);
      if (e.target.value === BUSINESS_TYPE.ALL) {
        handleBusinessTypeFilter(_toConsumableArray(Object.values(BUSINESS_TYPE_FOLDER).map(function (business) {
          return business.name;
        })));
        return;
      }
      handleBusinessTypeFilter([BUSINESS_TYPE_FOLDER[e.target.value].name]);
    }
  })));
}
export default Filters;