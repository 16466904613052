import React from "react";
var __jsx = React.createElement;
import { useContext, useEffect, useRef } from 'react';
import Para from '../../atoms/Para';
import ListComponent from '../GoogleMapsHappyHealthyPets/ListComponent';
import { Context } from './MapStateProvider';
import { BUSINESS_TYPE } from './constants';
import { useMap } from '@vis.gl/react-google-maps';
function SideBar(_ref) {
  var toggleMap = _ref.toggleMap,
    setToggleMap = _ref.setToggleMap,
    filteredApiData = _ref.filteredApiData,
    selectedBusinessType = _ref.selectedBusinessType;
  var _useContext = useContext(Context),
    allCoorLocations = _useContext.allCoorLocations,
    closestLocationsFromCenter = _useContext.closestLocationsFromCenter,
    handleSelectLocation = _useContext.handleSelectLocation,
    getMoreClosestLocations = _useContext.getMoreClosestLocations,
    isLoadingLocations = _useContext.isLoadingLocations,
    setSelectedLocationKey = _useContext.setSelectedLocationKey;
  var listContentRef = useRef();
  var map = useMap();
  useEffect(function () {
    var options = {
      threshold: 0,
      root: listContentRef.current
    };
    var observer = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting) {
        getMoreClosestLocations();
        observer.disconnect();
      }
    }, options);
    if (listContentRef.current) {
      var lastChild = listContentRef.current.children[listContentRef.current.children.length - 2];
      lastChild instanceof Element && observer.observe(lastChild);
    }
    return function () {
      observer.disconnect();
    };
  }, [closestLocationsFromCenter.length]);
  return __jsx("div", {
    className: "nva-maps-hhpets__side-bar side-bar ".concat(toggleMap ? '' : 'toggled is-toggled')
  }, __jsx("div", {
    className: "nva-maps-hhpets__display-locations-box display-locations-box"
  }, __jsx(Para, {
    className: "nva-maps-hhpets__display-locations display-locations"
  }, allCoorLocations === null || allCoorLocations === void 0 ? void 0 : allCoorLocations.length, " Locations")), __jsx("div", {
    className: "nva-maps-hhpets__list-content list-content",
    ref: listContentRef
  }, closestLocationsFromCenter === null || closestLocationsFromCenter === void 0 ? void 0 : closestLocationsFromCenter.map(function (item, index) {
    var _item$websiteUrl;
    return __jsx(ListComponent, {
      key: index,
      toggleMap: toggleMap,
      setTopOfSelectedCardToParent: function setTopOfSelectedCardToParent() {},
      itemIndex: index,
      name: item.name,
      item: item,
      selectedClinic: {},
      setSelectedClinic: function setSelectedClinic() {
        return handleSelectLocation(item);
      },
      address: item.address,
      website: item === null || item === void 0 ? void 0 : (_item$websiteUrl = item.websiteUrl) === null || _item$websiteUrl === void 0 ? void 0 : _item$websiteUrl.url,
      listOnClick: function listOnClick(_) {
        var site = allCoorLocations.find(function (site) {
          var _item$meta;
          return site.yId === (item === null || item === void 0 ? void 0 : (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.id);
        });
        setSelectedLocationKey(site.yId);
        handleSelectLocation(site);
        map.setCenter({
          lat: site.lat,
          lng: site.lng
        });
        map.setZoom(15);
      },
      coordinates: item.c_googleMyBusinessCID,
      reservationLink: item.reservationUrl && item.reservationUrl.url,
      phoneNumber: item.c_websitePhone || item.mainPhone,
      collapseButtonDisplay: true,
      businessType: item.c_businessType,
      setToggle: function setToggle() {
        setToggleMap(!toggleMap);
      }
    });
  }), isLoadingLocations && __jsx("div", {
    className: "loading"
  }, __jsx("p", null, "Loading Locations...")), !isLoadingLocations && (filteredApiData === null || filteredApiData === void 0 ? void 0 : filteredApiData.filter(function (each) {
    return selectedBusinessType === BUSINESS_TYPE.ALL ? each : each.c_businessType === selectedBusinessType;
  }).length) === 0 && __jsx("div", {
    className: "nva-maps-hhpets__no-locations no-locations-text"
  }, __jsx("p", null, "No locations are within this area. Please update your location or filters."))));
}
export default SideBar;